export default function browserDetection() {
	const parser = new UAParser()
	const nameBrowser = parser.getBrowser().name
	console.log(nameBrowser.replace(' ', '-'));
	document.body.classList.add(nameBrowser.replace(' ', '-'))

	window.nameBroswer = nameBrowser
	window.isFirefox = nameBrowser === 'Firefox'


	
}