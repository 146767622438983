export default function animationMain(){
  
  gsap.registerPlugin(
    CustomEase,
    ScrollTrigger
  )

  CustomEase.create("easeSuave", ".77,0,.175,1")  
  
  
}
  
  
  

  
    // const circuloHome = document.querySelector("#intro--luz")
    // const paths1 = document.querySelectorAll(".path--orna-1")
    // const paths2 = document.querySelectorAll(".path--orna-2")
    // const paths3 = document.querySelectorAll(".path--orna-3")
    // const paths4 = document.querySelectorAll(".path--orna-4")
    // const paths5 = document.querySelectorAll(".path--orna-5")
    // const paths6 = document.querySelectorAll(".path--orna-6")
    // const paths7 = document.querySelectorAll(".path--orna-7")
    // const allPaths = [paths1, paths2, paths3, paths4, paths5, paths6, paths7]
    // const svgsLinear = document.querySelectorAll("#paint1_radial_602_7652")
    // const svgsLinear2 = document.querySelectorAll("#paint2_radial_602_7652")
    // const luzIntro = document.querySelector('#intro--luz')
  
    // tl
    //   .set(circuloHome, {
    //     scale: 0.3
    //   })
    //   .set(allPaths, {
    //     // scale: 0,
    //     // opacity: 0,
    //     // rotation: -45,
  
    //   })
    //   .set(svgsLinear, {
    //     attr: { gradientTransform: "translate(719.5 -17.0114) rotate(146.292) scale(200.102 128.558)" }
    //   })
    //   .set(svgsLinear2, {
    //     attr: { gradientTransform: "translate(759 314.711) rotate(150.239) scale(116.922 172.382)" }
    //   })
    //   .to(allPaths, {
    //     scale: 1,
    //     opacity: 1,
    //     rotation: 0,
  
    //   })
    //   .to(circuloHome, {
    //     scale: 1,
    //     duration: 1
    //   }, "circulo")
    //   .to(svgsLinear, {
    //     attr: { gradientTransform: "translate(419.5 -17.0114) rotate(146.292) scale(300.102 228.558)" },
    //     duration: 1.6
    //   }, "circulo")
    //   .to(svgsLinear2, {
    //     attr: { gradientTransform: "translate(559 314.711) rotate(150.239) scale(116.922 172.382)" },
    //     duration: 1.2
    //   }, "circulo")
  
  
    // function elevateLight() {
    //   const tl = gsap.timeline({
    //     paused: true,
    //   })
  
    //   tl
    //     .fromTo(svgsLinear, {
    //       attr: { gradientTransform: "translate(419.5 -17.0114) rotate(146.292) scale(300.102 228.558)" },
    //     }, {
    //       attr: { gradientTransform: "translate(419.5 -17.0114) rotate(146.292) scale(400.102 228.558)" },
    //       // duration: 1.6
    //     }, "circulo")
  
    //     .fromTo(svgsLinear2, {
    //       attr: { gradientTransform: "translate(559 314.711) rotate(150.239) scale(116.922 172.382)" },
    //     }, {
    //       attr: { gradientTransform: "translate(459 214.711) rotate(150.239) scale(396.922 172.382)" },
    //       // duration: 1.6
    //     }, "circulo")
  
  
  
    //   return tl
    // }
    // window.elevateLight = elevateLight
  
    // ScrollTrigger.create({
    //   trigger: luzIntro,
    //   once: false,
    //   fastScrollEnd: false,
    //   start: "+=200",
    //   //markers: true,
    //   // snap: {
    //   //   snapTo: 1 / 100, // progress increment
    //   //   // or "labels" or function or Array
    //   //   duration: 2,
    //   //   directional: true,
    //   //   ease: "power3",
    //   //   delay: 0.5
  
    //   //   // other callbacks: onStart, onInterrupt
    //   // },
    //   // onToggle: self => console.log("toggled, isActive:", self.isActive),
    //   onUpdate: self => {
    //     console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
    //     elevateLight().progress(self.progress.toFixed(3))
    //   },
    //   onEnter: function () {
  
    //   },
  
    // });
  
  
  
  
  
  
    // new Rellax(luzIntro, {
    //   speed: -6,
    //   center: false,
    //   wrapper: null,
    //   round: true,
    //   vertical: true,
    //   horizontal: false
    // });
  
    /* 
      new Rellax("#circulo--data", {
        speed: -5,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false
      }); */
  
  
  
  
    // yellow circle
    // $(".link").on("mouseenter", function () {
    //   cursor.addClass("active");
    //   follower.addClass("active");
    // });
    // $(".link").on("mouseleave", function () {
    //   cursor.removeClass("active");
    //   follower.removeClass("active");
    // });
  
  
  
  
  
  
  
  
  
    // const circulos = [
    //   document.querySelector("#orna_varios_circulos #circulo_0"),
    //   document.querySelector("#orna_varios_circulos #circulo_1"),
    //   document.querySelector("#orna_varios_circulos #circulo_2"),
    //   document.querySelector("#orna_varios_circulos #circulo_3"),
    //   document.querySelector("#orna_varios_circulos #circulo_4"),
    //   document.querySelector("#orna_varios_circulos #circulo_5"),
    //   document.querySelector("#orna_varios_circulos #circulo_6"),
    //   document.querySelector("#orna_varios_circulos #circulo_7")
    // ]
  
  
