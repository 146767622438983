const props1 = () => ({
	slidesPerView: 'auto',
	spaceBetween: 32,
	centeredSlides: false,
	autoplay: {
		delay: 3000,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: ".swiper-pagination",
		type: 'bullets',
	},

})

const propsBlog = () => ({
	slidesPerView: 'auto',
	spaceBetween: 15,
	centeredSlides: false,
	// slidesPerGroup: 6,
	navigation: {
		nextEl: '.swiper-blog-swiper-button-next',
		prevEl: '.swiper-blog-swiper-button-prev',
	},
	pagination: {
		el: ".swiper-blog-pagination",
		type: 'bullets',
	},
	breakpoints: {

		740: {
			spaceBetween: 30,

		},

	}

})

const propsDepoimentos = () => ({
	slidesPerView: 'auto',
	spaceBetween: 10,
	centeredSlides: false,
	// slidesPerGroup: 6,

	pagination: {
		el: ".swiper-pagination-depoimentos",
		type: 'bullets',
	},

	breakpoints: {

		740: {
			slidesPerView: "auto",
			spaceBetween: 30,


		},

	}


})
const propsBasic = (opts) => ({
	slidesPerView: 'auto',
	spaceBetween: 8,
	centeredSlides: false,
	// slidesPerGroup: 6,

	// pagination: {
	// 	el: ".swiper-pagination-depoimentos",
	// 	type: 'bullets',
	// },
	...opts,

})


const propsBlogDestaque = () => ({
	slidesPerView: 1,
	spaceBetween: 10,
	centeredSlides: false,
	// slidesPerGroup: 6,
	grabCursor: true,
	effect: "fade",
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
	},

	breakpoints: {

		740: {
			spaceBetween: 30,


		},

	}


})

const propsPropostaServicos = () => ({
	slidesPerView: 1,
	// effect: "fade",
	centeredSlides: false,
	spaceBetween: 16,
	navigation: {
		nextEl: '.swiper-servicos-swiper-button-next',
		prevEl: '.swiper-servicos-swiper-button-prev',
	},
	pagination: {
		el: ".swiper-servicos-pagination",
		type: 'bullets',
	},
})


const propsPropostaDepoimentos = () => ({
	slidesPerView: 1,
	centeredSlides: false,
	spaceBetween: 16,
	pagination: {
		el: ".swiper-depoimentos-pagination",
		type: 'bullets',
	},
	breakpoints: {

		1200: {
			slidesPerView: 3,
		},

	}
})


export {
	props1,
	propsBlog,
	propsDepoimentos,
	propsBasic,
	propsBlogDestaque,
	propsPropostaServicos,
	propsPropostaDepoimentos
}