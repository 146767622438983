// import getElemetAndAppend from "./modules/getElementAndappend.js";
import * as swiperProps from "./modules/swiperProps.js";
import accordion from "./modules/accordion";
import navServicoesPropostas from "./modules/navServicoesPropostas.js";
import navServices from "./modules/navServicoes.js";
import carouselStack from "./modules/carouselStack.js";
import hoverFlip from "./animation/hoverFlip.js";
import initChoices from "./modules/initChoices.js";
import cursor from "./animation/cursor.js";
import carouselDestaque from "./modules/carouselDestaque.js";
import browserDetection from "./modules/browserDetection.js";
import menu from "./modules/menu.js";
import animaAoScroll from "./animation/animaAoScroll.js";
import rotateEl from "./animation/rotateEl.js";
import animationMain from "./animation/animationMain.js";
import modalVideo from "./modules/modalVideo.js";
import progressBar from "./modules/progressBar.js";
import loaderSection from "./modules/loaderSection.js";
import hide from "./animation/hide.js";
import fillFooterInMobile from "./modules/fillFooterInMobile.js";
import advancedPagination from "./modules/advancedPagination.js";
import growingNumberAnimation from "./modules/growingNumberAnimation.js";
import handlePasswordModal from "./modules/handlePasswordModal.js";


/////////////////////////////////////////////////////////////
// ESTE ARQUIVO PRECISA SER GERADO EM JS/DIST
// RODE PARA DESENVOLVIMENTO: npm run serve  
// RODE PARA PRODUÇAO: npm run build  
// no diretorio /assets
/////////////////////////////////////////////////////////////


const body = document.body;
const pageHome = document.querySelector(".page--home");
const pageServicos = document.querySelector(".page--servicos");
const pageContato = document.querySelector(".page--contato");
const pageBlog = document.querySelector(".page--blog");
const pageSingleBlog = document.querySelector(".page--blog-single");
const pageProposta = document.querySelector(".page--proposta");
const pageObrigado = document.querySelector(".page--obrigado");

function init() {
  browserDetection();
  animationMain();
  hoverFlip();
  menu();
  fillFooterInMobile();
  if (!tablet) cursor();

  //se nao for a single blog esconde os elementos para animar
  if (!pageSingleBlog) Array.from(document.querySelectorAll(".js-animate")).forEach((i) => hide(i));

  // se nao for single blog nem a home inicia animate ao scroll
  // na home eu inicio apos animacao do loader de seçao
  pageSingleBlog || pageHome || pageProposta ? null : animaAoScroll();

  //HOME
  if (pageHome) {
    new Swiper(".parceiros--carousel", swiperProps.props1());
    new Swiper(".swiper--blog", swiperProps.propsBlog());
    new Swiper(".depoimentos--swiper", swiperProps.propsDepoimentos());

    navServices().init("servico-1");
    carouselStack().init();
    modalVideo();
    loaderSection();
    rotateEl();

    if (!tablet)
      VanillaTilt.init(document.querySelector("[data-tilt]"), { max: 10, perspective: 100 });
    if (tablet) {
      window.navSwiper = new Swiper(
        ".servicos .swiper--nav",
        swiperProps.propsBasic({
          centeredSlides: true,
          navigation: {
            nextEl: ".swiper-button-next-servicos",
            prevEl: ".swiper-button-prev-servicos",
          },
        })
      );
    }

    document.querySelector(".animate--main").appendChild(document.querySelector(".menu"));
  }

  // SERVIÇOS
  if (pageServicos) {
    /*  */
  }

  // CONTATO
  if (pageContato) {
    const selectsFormContato = Array.from(document.querySelectorAll(".form--container select"));
    selectsFormContato.map(initChoices);
    if (!tablet) VanillaTilt.init(document.querySelector("[data-tilt]"));
  }

  // BLOG
  if (pageBlog) {
    new Swiper(".destaque .swiper", swiperProps.propsBlogDestaque());
    carouselDestaque({ autoPlay: true, autoPlayTime: 4500 }).init();
    advancedPagination().init();
  }

  // SINGLE BLOG
  if (pageSingleBlog) {
    new Swiper(".swiper--blog", swiperProps.propsBlog());
    progressBar();
  }

  // PROPOSTA
  if (pageProposta) {
    new Swiper(".swiper--servicos", swiperProps.propsPropostaServicos());
    new Swiper(".swiper--depoimentos", swiperProps.propsPropostaDepoimentos());

    window.navSwiper = new Swiper(
      ".servicos .swiper--nav",
      swiperProps.propsBasic({
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next-servicos",
          prevEl: ".swiper-button-prev-servicos",
        },
      })
    );

    // FANCY GALLERY
    Fancybox.bind(".cases--cards a", {
      groupAll: true,
    });

    rotateEl();
    accordion();
    handlePasswordModal().init();

    navServicoesPropostas({ handleClick: true }).init("servico-1", true);
    growingNumberAnimation().init();
    document.querySelector(".animate--main").appendChild(document.querySelector(".menu"));
  }

  // Obrigado
  if (pageObrigado) {
    rotateEl();
  }

  body.classList.add("DOMContentLoaded");
}


// EVENTOS 🧙‍♂️
document.addEventListener("DOMContentLoaded", init)

console.log('aaaaaaaaaaaaaaaaaaaaaaaaa')