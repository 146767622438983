export default function navServices() {

	const secaoServices = document.querySelector(".servicos")
	const contentWrapper = secaoServices.querySelector(".content--wrapper")
	const navLIs = secaoServices.querySelectorAll(".nav--wrapper li")


	let opts = {
		click: false,
		autoPlayTime: 3500
	}

	function hideText(textElement) {
		const elements = textElement.querySelectorAll('.icon,h2,.desc,a')
		return new Promise(resolve => gsap.to(elements, {
			opacity: 0,
			y: -100,
			duration: .4,
			ease: 'sine.out',
			delay: 0.3,
			stagger: 0.08,

			onComplete: () => resolve(textElement)
		}))

	}

	function showText(textElement) {
		const elements = textElement.querySelectorAll('.icon,h2,.desc,a')

		return new Promise(resolve => {
			gsap.set(elements, {
				opacity: 0,
				y: 100,
			})
			gsap.to(elements, {
				opacity: 1,
				y: 0,
				duration: .45,
				delay: 0.05,
				ease: 'sine',
				stagger: .06,

				onComplete: () => resolve(textElement)
			})
		})

	}

	function hideShowImg(imgElement, nextImg) {
		return new Promise(resolve => {
			nextImg.classList.add("active")
			const tl = gsap.timeline()

			tl
				.set(nextImg, {
					y: "100%",
					scale: 0.8,
					boxShadow: "initial",
					borderRadius: 8,

				})
				.to(imgElement, {
					scale: 0.8,
					boxShadow: "0,2px,3px black",
					duration: .4,
					ease: "power1",
					borderRadius: 8,

				})
				.to(imgElement, {
					y: "-100%",
					duration: .5,
					ease: "power1.out",


				}, "image")
				.to(nextImg, {
					duration: .4,
					scale: 1,
					delay: 0.05,
					transformOrigin: "center center",
					ease: "power1",

				})
				.to(nextImg, {
					y: "0",
					duration: .5,
					ease: "power1.out",

					onComplete: () => {
						imgElement.classList.remove("active")
						resolve(imgElement)
					}
				}, "image")
				.set(nextImg, {
					borderRadius: 0,

				})


		})

	}

	function setAllActive(nameOfService) {
		document.querySelectorAll(`[data-servico="${nameOfService}"]`)
			.forEach(i => i.classList.add("active"))

	}

	function setActive(element) {
		element.classList.add("active")
		return element

	}

	function addEventsOnNavs() {
		navLIs.forEach(li => li.addEventListener("click", changeActive))


	}

	function addEventsOnButtons() {
		const buttons = secaoServices.querySelectorAll(".servicos--buttons button")

		if (!buttons) { return }
		buttons.forEach(btn => btn.addEventListener("click", changeActive))
	}

	function changeNavActive(nameOfService) {
		navLIs.forEach(li => li.classList.remove("active"))
		document.querySelector(`.nav--wrapper li[data-servico="${nameOfService}"]`)
			.classList.add("active")

	}

	let mudanceState = true
	function changeActive(event) {
		if(!mudanceState) return
		mudanceState = false
		if (event.isTrusted && mobile) {
			opts.click = true
			navSwiper.slideTo([...navLIs].indexOf(event.currentTarget))
		}

		const target = event.currentTarget
		const nameOfService = target.dataset.servico

		const nextText = contentWrapper.querySelector(`.text[data-servico="${nameOfService}"]`)
		const textActive = contentWrapper.querySelector(`.text.active`)

		const imgActive = contentWrapper.querySelector(`.img--servico.active`)
		const nextImage = contentWrapper.querySelector(`.img--servico[data-servico="${nameOfService}"]`)

		if (nextText === textActive || !nameOfService){
			mudanceState = true

			return
		}

		hideShowImg(imgActive, nextImage)

		changeNavActive(nameOfService)

		hideText(textActive)
			.then(el => el.classList.remove("active"))
			.then(el => setActive(nextText))
			.then(el => {
				showText(nextText)
				mudanceState = true
			});

		// animationGrave()


	}

	function autoPlay() {
		const listOfLiss = [...navLIs]
		let count = 0

		function initInterval() {

			const interval = setInterval(() => {
				if (opts.click) {
					clearInterval(interval)
					return
				}
				const event = new Event("click")
				listOfLiss[count].dispatchEvent(event)
				navSwiper.slideTo(count)

				if (count === listOfLiss.length - 1) {
					count = 0
					return
				}

				count++

			}, opts.autoPlayTime)
		}
		const servicos = document.querySelector('.servicos')

		let options = {
			root: servicos,
			rootMargin: '0px',
			threshold: 1.0
		}

		let observer = new IntersectionObserver(initInterval, options);
		observer.observe(servicos);

	}

	function prevSlide() {
		const active = navSwiper.activeIndex - 1
		navLIs[active].click()
		opts.click = true
	}

	function nextSlide() {
		const active = navSwiper.activeIndex + 1
		navLIs[active].click()
		opts.click = true
	}

	function handleChangeActiveMobile() {
		const prev = document.querySelector(".swiper-button-prev-servicos")
		const next = document.querySelector(".swiper-button-next-servicos")

		if (!prev || !next) { return }

		prev.addEventListener("click", prevSlide)
		next.addEventListener("click", nextSlide)
	}

	function init(nameOfService) {
		console.log(mobile);
		addEventsOnNavs()
		addEventsOnButtons()
		setAllActive(nameOfService)
		mobile ? autoPlay() : ""
		mobile ? handleChangeActiveMobile() : ""

		return this
	}


	return {
		init,
		prevSlide,
		nextSlide
	}
}