export default function advancedPagination() {

	const loader = `<div class='request--loader'></div>`
	const left = document.querySelector(".arrow--left")
	const right = document.querySelector(".arrow--right")

	const itemTemplate = (response) => {
		const { capa, tag, titulo, resumo, get_absolute_url, usuario_criacao_full_name, usuario_criacao_url_foto } = response
		const location = window.location.origin
		return `
			<article class="blog--item swiper-slide mouse--active" data-texto="Veja" style='opacity:0'>
				<a href="${location}${get_absolute_url}">
					<picture>
						<img src="${capa}" alt="imagem capa sobre o post">
					</picture>

					<div class="blog--text">
						<span class="tag">${tag}</span>
						<h3>${titulo}</h3>
						<p>${resumo}</p>
					</div>
					<div class="avatar">
						<img src="${usuario_criacao_url_foto}" alt="imagem do criador do post">
						<p>${usuario_criacao_full_name}</p>
					</div>
				</a>
			</article>
	`
	}



	const templatePagination = (content, classe) => `<li data-valor="${content}" class="cursor--pointer ${classe ? classe : ""}"><p>${content}</p></li>`
	let paginationObject
	let itemsPerPage = 8


	function populateObject(obj) {
		const entries = Object.entries(obj)
		entries.forEach(i => {
			const [key, value] = i
			obj[key] = value
		})

		return obj
	}

	async function generatePagination(request) {
		const ul = document.querySelector(".paginate ul")

		if (request.data.ultima_pagina === 1) {
			ul.closest(".paginate").style.display = "none"
			return
		}


		const {
			pagina_selecionada,
			primeira_pagina,
			proxima_pagina,
			ultima_pagina
		} = await request.data


		paginationObject = populateObject(request.data)


		// const arrayTamanhoTotal = [...Array(ultima_pagina).keys()].map(i => i + 1)
		const arrayTamanhoTotal = [...Array(ultima_pagina).keys()].map(i => i + 1)

		const verifyShowPage = (i, index) => {
			if (i === primeira_pagina) return true
			else if (i === ultima_pagina) return true
			else if (i === pagina_selecionada) return true
			else if (i === proxima_pagina) return true
			else return false

		}



		const verifyIsActiveAndGenerateTemplate = (i) =>
			i === pagina_selecionada ? templatePagination(i, "active") : templatePagination(i)

		const addThreePoints = (acc, i, index, array) => {
			acc.push(i)

			if (index === array.length - 2 && array.length >= 3) {
				if (pagina_selecionada + 1 === ultima_pagina) {
					return acc
				}
				acc.push(templatePagination("...", "pointer-events-none"))
			}
			return acc

		}


		const template = arrayTamanhoTotal
			.filter(verifyShowPage)
			.map(verifyIsActiveAndGenerateTemplate)
			.reduce(addThreePoints, [])

		ul.innerHTML = template.join("")

		ul.querySelectorAll("li").forEach(i => {
			i.addEventListener("click", (e) => {
				const target = e.currentTarget
				const atributo = target.dataset.valor

				if (Number(atributo)) {
					requestPosts(Number(atributo), itemsPerPage)
				}

			})
		})


		// add no botao da seta atributo da proxima pagina e da anterior

	}

	function addEventsInArrows() {
		left.addEventListener("click", () => {
			if (paginationObject.pagina_anterior) {
				requestPosts(paginationObject.pagina_anterior, itemsPerPage)

			}
		})

		right.addEventListener("click", () => {
			if (paginationObject.proxima_pagina) {
				requestPosts(paginationObject.proxima_pagina, itemsPerPage)

			}
		})

	}





	function populateAndAnime(wrapper, template) {

		wrapper.innerHTML = template.join("")
		gsap.to(wrapper.querySelectorAll('.blog--item'), {
			opacity: 1,
			duration: 0.45,
			stagger: 0.06,

		})
	}

	function animateElsSaida(wrapper) {
		return new Promise(resolve => {
			gsap.timeline()
				.set(wrapper.querySelectorAll('.blog--item'), { pointerEvents: "none" })
				.to(wrapper.querySelectorAll('.blog--item'), {
					opacity: 0,
					duration: 0.45,
					stagger: 0.06,
					onComplete: () => resolve()
				})
		})
	}


	async function requestPosts(pagina_selecionada, limite, needPopulate = true) {
		const wrapper = document.querySelector(".novidades--wrapper")
		const height = wrapper.getBoundingClientRect().height
		let animou
		if (needPopulate) {
			//joga o loader na div
			wrapper.insertAdjacentHTML('beforeend', loader)

			//defino o tamanho para n pular a tela
			wrapper.style.setProperty('height', `${height}px`)
			animou = animateElsSaida(wrapper)
		}

		const data = {
			"pagina_selecionada": pagina_selecionada,
			"limite": limite
		}

		const request = await axios.post(urls.requisitarPosts, data)
		const posts = await request.data.posts
		paginationObject = populateObject(await request.data)

		if (!needPopulate) return await request

		const template = await posts.map(itemTemplate)
		// wrapper.style.removeProperty('height')
		wrapper.closest(".novidades")
			.querySelector(".theme--h1").scrollIntoView({ behavior: "smooth" })
		await Promise.all([animou, template])
		//espera animar e gerar o template
		populateAndAnime(wrapper, template)
		generatePagination(await request)

	}




	async function init() {
		const request = await requestPosts(1, itemsPerPage, false)
		generatePagination(await request)
		addEventsInArrows()

	}

	return {
		init,

	}
}