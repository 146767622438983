export default function hoverFlip() {
	const flips = document.querySelectorAll('.flip')

	CustomEase.create('flip', '0.19, 1, 0.22, 1')

	flips.forEach(i => {
		const a = i.closest('a') || i.closest('button')
		const span = i.querySelector('span')
		const text = span.innerHTML.trim()

		const newEl = document.createElement('p')
		newEl.classList.add('flip--text')
		newEl.innerHTML = text
		
		i.appendChild(newEl)


		gsap.set(newEl, {
			y: '200%',
			skewY: -15

		})

		const flip = gsap.timeline({
			paused: true,
			delay: 0
		})
			.from(newEl, {
				y: '200%',
				duration: 0,
				skewY: -15
			}, 'init')
			.from(span, {
				y: 0,
				duration: 0,
				skewY: 0
			}, 'init')
			.to(span, {
				y: '-200%',
				duration: 0.8,
				ease: "flip",
				skewY: 15
			}, 'init')
			.to(newEl, {
				y: 0,
				duration: 0.8,
				ease: "flip",
				skewY: 0
			}, 'init')


		a.addEventListener('mouseenter', () => {
			flip.timeScale(1).play()
		})
		a.addEventListener('mouseleave', () => {
			flip.seek(0.5).timeScale(1.1).reverse()
		})


	})

}