import logoAnimation from "../animation/logoAnimation.js";
import animaAoScroll from "../animation/animaAoScroll.js";

export default function loaderSection() {

	const loaderSection = document.querySelector(".loader--section")
	const mainSection = document.querySelector(".animate--main")
	const logoAnim = logoAnimation(".loader--section")
	const menuLi = document.querySelectorAll(".menu--wrapper ul li a")
	window.logoAnim = logoAnim
	CustomEase.create("easeSuave", ".77,0,.175,1")

	// estado inicial 
	gsap.set(mainSection, {
		yPercent: 180,
		skewY: 10,
		scale: 2
	})


	logoAnim.logoAnimationToNDT.progress(1).pause()

	const bodyclass = () => {
		document.body.classList.add("loader--finalizou")
		mainSection.removeAttribute("style")

		scrollToCases()
		// var data = new Date().getHours();
		// var aviso = data + 1;
		// localStorage.setItem('loaderData', aviso);

	}

	function scrollToCases() {
		const url = new URL(window.location)
		if (url.searchParams.has("section")) {

			const section = document.querySelector(`#${url.searchParams.get("section")}`)
			section.scrollIntoView({ behavior: "smooth" })
		}

	}


	const tlMae =
		gsap.timeline({ ease: 'power1', transformOrigin: "top center", delay: 0.5, })
			.set(menuLi, { yPercent: -200, })
			.add(logoAnim.logoAnimationToNDT.reverse())
			.to(loaderSection, { duration: 1.5, skewY: 10, scale: 2, onStart: () => window.scrollTo(0, 0) }, "logo")
			.to(loaderSection, { duration: 1, yPercent: -180 }, "logo")
			.set(mainSection, { yPercent: 180 }, "<") //subida do loader
			.to(mainSection, { skewY: 0, duration: 0.5, onComplete: () => animaAoScroll(), ease: "linear" }, "logo")
			.to(mainSection, { duration: 1.5, yPercent: 0, }, "logo-=0.78")
			.to(mainSection, { duration: 1, scale: 1, clearProps: "transform" }, "logo-=0.98")
			.to(menuLi, { yPercent: 0, stagger: 0.2, duration: 0.4, onComplete: () => bodyclass() }, "logo+=0.5")
			.timeScale(0.9)
	// animaAoScroll()




	// window.tlMae = tlMae

	// if (localStorage.getItem("loaderData")) {
	// 	const verificar = localStorage.getItem("loaderData")
	// 	var dataagora = new Date().getHours();
	// 	if (verificar >= dataagora) {
	// 		tlMae.progress(1)

	// 	}else{
	// 		localStorage.removeItem("loaderData")
	// 	}
	// }


}