export default function carouselStack() {
	// ver quantidade de slides,
	// pegar e setar o ativo
	// pegar e setar o proximo e o anterior 

	const carouselContainer = document.querySelector(".carousel--container")
	const carouselWrapper = carouselContainer.querySelector(".carousel--wrapper")
	const carouselSlides = () => Array.from(carouselWrapper.querySelectorAll(".carousel--slide"))
	const abaixo90rem = window.matchMedia("(max-width: 90rem)").matches
	const paginationWrapper = document.querySelector(".carousel--pagination")

	const nextButton = () => document.querySelector(".carousel--button-next")
	const prevButton = () => document.querySelector(".carousel--button-prev")

	if(!carouselSlides()?.length) return {init: () => null}

	const textItems = carouselContainer.querySelectorAll(".carousel--text .text--item")
	let mudanceState = true

	let propsTransitionSlides = carouselSlides()

	const circleOrna = Array.from(document.querySelectorAll("#orna--circulo-cases circle"))

	let opts = {
		numberSlides: carouselSlides().length,
		slidesVisiveis: 4,
		slideActive: () => carouselSlides().filter(slide => slide.classList.contains("active"))[0],
		nextSlide: () => opts.slideActive().nextElementSibling,
		prevSlide: () => opts.slideActive().previousElementSibling, //funciona meio mal to pegando direto do array com o index
		indexPrevSlide: () => {
			const index = carouselSlides().indexOf(opts.prevSlide())
			if (index === -1) return opts.numberSlides - 1
			return index

		},
		indexNextSlide: () => carouselSlides().indexOf(opts.nextSlide()),
		indexActualSlide: () => carouselSlides().indexOf(opts.slideActive()),
		mudanceStateTrue: () => mudanceState = true,
		mudanceStateFalse: () => mudanceState = false
	}

	const {
		numberSlides,
		slidesVisiveis,
		slideActive,
		nextSlide,
		prevSlide,
		indexPrevSlide,
		indexNextSlide,
		indexActualSlide,

	} = opts

	const removeActiveForLastSlide = () => carouselSlides()[carouselSlides().length - 1].classList.remove("active")
	// const removeActiveForSlide = (index) => carouselSlides()[index].classList.remove("active")
	const setActiveOnSlide = (index) => carouselSlides()[index].classList.add("active")


	const setAttributeDataSlide = (item, index) => {
		item.setAttribute("data-slide", index)
		textItems[index].setAttribute("data-slide", index)
	}

	function initialState() {
		// const tl = gsap.timeline()
		setActiveOnSlide(0)

		const yValue = abaixo90rem ? 18 : 32

		const initialProps = (index) => ({
			scale: 1 - (index * .05),
			y: -index * yValue,
			zIndex: -index,
			opacity: index > slidesVisiveis - 1 ? 0 : 1,
			filter: index > 0 ? "brightness(0.65)" : '',
			// duration: 0.5

		})

		propsTransitionSlides = propsTransitionSlides.map((obj, index) => {
			obj.props = initialProps(index)
			return obj
		})


		carouselSlides().forEach((slide, index) => gsap.set(slide, {
			transformOrigin: "top center",
			...initialProps(index),

		}))

		prevButton().classList.add("disabled")


	}



	function createPagination() {
		const template = carouselSlides().map((slide, index) =>
			`<span data-slide="${slide.dataset.slide}" ${index === 0 ? `class="active"` : ""} ></span>`)

		paginationWrapper.innerHTML = template.join("")

	}

	function updatePagination() {
		paginationWrapper.querySelectorAll("span").forEach(item => item.classList.remove("active"))
		paginationWrapper.querySelectorAll("span").forEach(item => {
			if (item.dataset.slide === carouselSlides()[opts.indexActualSlide()].dataset.slide) {
				item.classList.add("active")
			}
		})


	}



	function hideText(textElement, direction) {
		return new Promise(resolve => gsap.to(textElement, {
			opacity: 0,
			y: direction ? 100 : -100,
			duration: .3,
			// ease: "sine",
			onComplete: () => resolve(textElement)
		}))

	}

	function showText(textElement, direction) {
		return new Promise(resolve => {
			gsap.set(textElement, {
				opacity: 0,
				y: direction ? -90 : 90,
			})
			gsap.to(textElement, {
				opacity: 1,
				y: 0,
				duration: .4,
				// delay:0.1,
				ease: "power1",
				transformOrigin: direction ? "left center" : "top center",

				onComplete: () => resolve(textElement)
			})
		})

	}


	function refreshActiveSlide(direction) {
		prevButton().classList.remove("disabled")

		if (direction === "next") {
			setActiveOnSlide(indexNextSlide())
			carouselWrapper.appendChild(slideActive())
			removeActiveForLastSlide()

		} else {
			carouselWrapper.insertAdjacentElement("afterbegin", carouselSlides()[indexPrevSlide()])
			setActiveOnSlide(indexPrevSlide())
			carouselWrapper.children[1].classList.remove("active")
		}

		carouselSlides().map((slide, index) => {
			return gsap.timeline({
				onComplete: () => {
					opts.mudanceStateTrue()
				}

			}).to(slide, {
				...propsTransitionSlides[index].props,
			})


		})

		updatePagination()

	}


	function refreshTextActive(direction) {
		const textActive = carouselContainer.querySelector(`.text--item.active`)

		const indexActive = direction == "next" ?
			nextSlide().dataset.slide :
			carouselSlides()[indexPrevSlide()].dataset.slide

		const newTextActive = carouselContainer.querySelector(`.text--item[data-slide="${indexActive}"]`)
		const directionOftext = direction === "next" ? true : false

		hideText(textActive, directionOftext)
			.then(el => el.classList.remove("active"))
			.then(text => {
				newTextActive.classList.add("active")
				showText(newTextActive, directionOftext)
			})

	}

	function animateActiveToNext() {
		return gsap.timeline()
			// .to(slideActive(), {
			// 	y: 20,
			// 	// rotate: -3,
			// 	duration: .2,
			// 	ease: "power3",


			// })
			// .to(slideActive(), {

			// }, "-=0.2")

			.fromTo(slideActive(), {
				opacity: 1,

			}, {
				y: 40,
				opacity: 0,
				rotate: 0,
				duration: .2,
				ease: "power1.out",

				onComplete: () => refreshActiveSlide("next")
			})
			.set(slideActive(), {
				zIndex: propsTransitionSlides[propsTransitionSlides.length - 1].props.zIndex
			})
	}

	const circulos = [...document.querySelectorAll("#orna--circulo-cases path")]
	function animateActiveToPrev() {
		const prev = carouselSlides()[indexPrevSlide()]

		gsap.fromTo(slideActive(), {
			filter: "brightness(1)",
		}, {
			filter: "brightness(0.65)",

		})

		// gsap.timeline()
		// 	// .from(circulos, {
		// 	// 	x: 0,
		// 	// 	duration: 0,
		// 	// 	stagger: 0.03,
		// 	// 	ease: "sine",
		// 	// 	opacity: 0.05,
		// 	// })
		// 	.to(circulos, {
		// 		x: -60,
		// 		duration: 0.4,
		// 		stagger: 0.01,
		// 		// ease: "sine",
	

		// 	}, "<")
		// 	.to(circulos, {
		// 		x: 0,
		// 		duration: 0.3,
		// 		stagger: 0.09,
		// 		ease: "sine",
		// 		opacity: 1,
		// 	})

		return gsap.timeline({
			smoothChildTiming: false,
		})
			.set(prev, {
				scale: 1.02,
				zIndex: 1,
				opacity: 0,
				y: "20%",
			})
			.fromTo(prev, {
				opacity: 0,
				ease: "power3",
				// duration: 5.5,

			}, {
				y: "3%",
				opacity: 1,
				duration: 0.5,
				ease: "power1.out",

			})
			.to(slideActive(), {
				duration: 0,
				y: 0,
				scale: 1,
				onComplete: () => refreshActiveSlide("prev")
			}, "<")


	}


	function handleNextSlide() {
		if (mudanceState) {
			opts.mudanceStateFalse()
			refreshTextActive("next")
			animateActiveToNext()
			// animationGrave2(circleOrna)
		}

	}

	function handlePrevSlide() {

		if (mudanceState) {
			opts.mudanceStateFalse()
			refreshTextActive("prev")
			animateActiveToPrev()
			// animationGrave2(circleOrna)

		}


	}


	function addEvents() {
		nextButton().addEventListener("click", handleNextSlide)
		prevButton().addEventListener("click", handlePrevSlide)


	}

	function addPanEvents() {
		const hammerCarousel = new Hammer(carouselWrapper)

		hammerCarousel.on('panleft panright panup pandown', (ev) => {
			const {
				type
			} = ev

			type === "panleft" && mudanceState ? handlePrevSlide() : null
			type === "panright" && mudanceState ? handleNextSlide() : null
			// type === "panup" && mudanceState ? handleNextSlide() : null
			// type === "pandown" && mudanceState ? handlePrevSlide() : null




		});
	}

	function changeButtonsIfMobile() {
		if (tablet) {
			const controles = document.querySelector('.controles')
			const casesWrapper = document.querySelector('.cases--wrapper')
			casesWrapper.appendChild(controles)
		}
	}

	function init() {
		changeButtonsIfMobile()
		carouselSlides().forEach(setAttributeDataSlide)
		initialState()
		addEvents()
		createPagination()
		addPanEvents()
		return this


	}



	return {
		init,
		opts,
		handleNextSlide,
		nextButton,
		prevButton
	}


}