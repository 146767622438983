export default function growingNumberAnimation() {
  const sectionNumber = document.querySelector(".number");

  function numeroCresce() {
    if (sectionNumber.classList.contains("passou") && !sectionNumber.classList.contains("terminou")) {
      let numeros = document.querySelectorAll(".number .number__item span b")

      console.log(numeros);

      numeros.forEach((i) => {

        var min = 0;
        var max = i.closest('span').dataset.value;
        var duracao = 1000; // 5 segundos

        for (var n = min; n <= max; n++) {
          setTimeout(function (nr) {
            i.innerHTML = nr;
          }, n * duracao / max, n);
        }

      })
      sectionNumber.classList.add("terminou")


    }

  }

  function chegouNaSecao() {
    const sectionTop = sectionNumber.getBoundingClientRect().top;
    if (sectionTop < 750) {
      setTimeout(() => {
        sectionNumber.classList.add("passou")
        numeroCresce()
      }, 300);
    }
  }


  function init() {
    window.addEventListener("scroll", chegouNaSecao)
    return this
  }

  return { init }
}