
import logoAnimation from "../animation/logoAnimation.js";

export default function menu() {
	const logoAnim = logoAnimation(".menu")
	const toogle = document.querySelector('.menu--wrapper li.toogle')
	const menuMobile = document.querySelector('.menu .menu--mobile')
	const itemsDoMenu = [...menuMobile.querySelectorAll('.menu--link a, h3, .redes--wrapper a')]

	const linhaDoMenu1 = toogle.querySelector('i:nth-child(1)')
	const linhaDoMenu2 = toogle.querySelector('i:nth-child(2)')

	itemsDoMenu.forEach(i => i.addEventListener("click", () => toogle.click()))

	CustomEase.create("easeSuave", ".77,0,.175,1")

	const opts = {
		open: false

	}
	let { open } = opts


	const tlItensMenu = () => gsap.timeline()
		.from(itemsDoMenu, {
			yPercent: 320,
			skewY: 15,
			stagger: 0.07,

		})
		.to(menuMobile.querySelectorAll('a,h3'), {
			yPercent: 0,
			skewY: 0,
			stagger: 0.07,
			// duration: 0.5,
			ease: "easeSuave"
		})

	const tlTraco = () => gsap.timeline()
		.from(linhaDoMenu2, {
			y: 0,
		})
		.to(linhaDoMenu2, {
			y: -10,
			ease: "easeSuave",
			duration: 0.4

		})



	const tlOpenMenu = () => gsap.timeline()
		.from(menuMobile, {
			rotate: 50,
			yPercent: mobile ? 500 : 300,
			scale: 5.5,
			skewY: 20,

		})
		.to(menuMobile, {
			yPercent: 0,
			rotate: 0,
			skewY: 0,
			scale: 1,
			transformOrigin: 'center center'

		})

	const tlMenu =
		gsap.timeline({
			paused: true, ease: "easeSuave", duration: 0.5, onReverseComplete: () => menuMobile.classList.remove('active')
		})
			.add(tlOpenMenu(), 'open')
			.add(tlItensMenu(), '-=0.65')
			.add(tlTraco(), 'open-=0.3')

	window.tlOpenMenu = tlOpenMenu
	window.tlItensMenu = tlItensMenu

	window.tlMenu = tlMenu

	toogle.addEventListener(
		'click', () => {
			if (open) {
				open = false

				tlMenu
					.progress(0.6)
					.reverse()

				logoAnim.logoAnimationToNDT
					.reverse()
				document.body.style.overflow = "initial"

				console.log('fechou')
			} else {
				menuMobile.classList.add('active')
				open = true
				console.log('abriu')

				logoAnim.logoAnimationToNDT
					.progress(0.3)
					.play()

				tlMenu
					.progress(0.2)
					.play()
				document.body.style.overflow = "hidden"

			}
		})

	if (!open) {

		gsap.set(itemsDoMenu, {
			yPercent: 320,
			skewY: 15
		})

		gsap.set(menuMobile, {
			rotate: 30,
			skewY: 20,
			yPercent: 300,
			scale: 3,
			transformOrigin: 'top'

		})

	}




}

// export default function menu() {
// 	const toogle = document.querySelector('.menu--wrapper li.toogle')
// 	const menuMobile = document.querySelector('.menu .menu--mobile')
// 	const itemsDoMenu = [...menuMobile.querySelectorAll('.menu--link a, h3, .redes--wrapper a')]

// 	const linhaDoMenu1 = toogle.querySelector('i:nth-child(1)')
// 	const linhaDoMenu2 = toogle.querySelector('i:nth-child(2)')

// 	CustomEase.create("easeSuave", ".77,0,.175,1")

// 	const opts = {
// 		mudanceState: true,
// 		open: false,

// 		startItemsMenu: {
// 			yPercent: 250,
// 			skewY: 15,
// 			rotate: -7,

// 		},

// 		startMenu: {
// 			rotate: 30,
// 			skewY: 25,
// 			yPercent: 300,
// 			scale: 3,
// 			transformOrigin: 'top'

// 		}

// 	}
// 	let { open, startMenu, startItemsMenu, mudanceState } = opts

// 	//////////////////////////////////////////////
// 	const ItensMenuTL = () =>
// 		gsap.timeline()
// 			.from(itemsDoMenu, startItemsMenu)
// 			.to(menuMobile.querySelectorAll('a,h3'), {
// 				yPercent: 0,
// 				stagger: 0.05,
// 				skewY: 0,
// 				rotate: 0,
// 				duration: 1,
// 				ease: "easeSuave",
// 				transformOrigin: 'bottom'

// 			})


// 	const OpenMenuTL = () =>
// 		gsap.timeline()
// 			.from(menuMobile, startMenu)
// 			.to(menuMobile, {
// 				yPercent: 0,
// 				rotate: 0,
// 				skewY: 0,
// 				scale: 1,
// 				transformOrigin: 'center center',

// 			})



// 	const TracoTL = () =>
// 		gsap.timeline()
// 			.from(linhaDoMenu2, {
// 				y: 0,
// 			})
// 			.to(linhaDoMenu2, {
// 				y: -10,
// 				ease: "easeSuave",
// 				duration: 0.2

// 			})




// 	const MenuTL =
// 		gsap.timeline({ paused: true, ease: "easeSuave", duration: 0.5, overwrite: false, immediateRender: true })
// 			.add(OpenMenuTL(), 'open')
// 			.add(ItensMenuTL(), '-=0.65')
// 			.add(TracoTL(), 'open-=0.3')



// 	toogle.addEventListener('click', () => {
// 		if (open) {
// 			open = false
// 			menuMobile.classList.remove('active')
// 			MenuTL
// 				.progress(0.6)
// 				.reverse()


// 		}
// 		else if (!open) {
// 			menuMobile.classList.add('active')
// 			open = true

// 			MenuTL
// 				// .progress(0.2)
// 				.play()
// 				.call(console.log)

// 		}
// 	})

// 	if (!open) {
// 		gsap.set(itemsDoMenu, startItemsMenu)
// 		gsap.set(menuMobile, startMenu)

// 	}


// 	window.OpenMenuTL = OpenMenuTL
// 	window.ItensMenuTL = ItensMenuTL
// 	window.MenuTL = MenuTL


// }