export default function cursor() {

	if (disableCursor) {
		document.body.classList.add("disable--cursors")
		return
	}

	var cursor = document.querySelector(".cursor"),
		follower = document.querySelector(".cursor-follower"),
		follower2 = document.querySelector(".cursor-follower.follower2"),
		follower3 = document.querySelector(".cursor-follower.follower3"),
		follower4 = document.querySelector(".cursor-follower.follower4"),
		circulo = document.querySelector(".cursors .circulo"),
		circuloContainer = document.querySelector(".cursors .circulo--container"),
		textInside = document.querySelector(".cursors .text--inside")
	var posX = 0,

		posY = 0;

	var mouseX = 0,
		mouseY = 0;

	let isLink = false
	let isPointer = false

	function rotate(el) {
		return gsap.timeline()
			.to(el, {
				rotate: 360,
				duration: 7.5,
				ease: "linear",
				repeat: -1
			})

	}

	gsap.set(circuloContainer, { scale: 0 })

	const rotateCirculoCursor = rotate(circulo)

	const circuloTL =
		gsap.timeline({
			paused: true
		}).from(circuloContainer, {
			scale: 0,
			duration: 0,
			transformOrigin: "center center"

		}).to(circuloContainer, {
			scale: 1,
			duration: 0.3,
			transformOrigin: "center center"
		})

	const ScaleCursorTL =
		gsap.timeline({
			paused: true
		}).from([cursor, follower, follower2, follower3, follower4], {
			scale: 0,
			duration: 0
		}).to([cursor, follower, follower2, follower3, follower4], {
			scale: 1,
			duration: 0.3,

		})

	const XsetCirculoContainer = gsap.quickSetter(circuloContainer, 'x', 'px')
	const YsetCirculoContainer = gsap.quickSetter(circuloContainer, 'y', 'px')
	const XcursorSet = gsap.quickSetter(cursor, 'x', 'px')
	const YcursorSet = gsap.quickSetter(cursor, 'y', 'px')
	const cursorOpacity = gsap.quickSetter(cursor, 'opacity')
	const cursoresOpacity = (v) => gsap.to([cursor, follower, follower2, follower3, follower4], {
		opacity: v,
		duration: 0.1

	})

	gsap.ticker.add(() => {
		posX += (mouseX - posX) / 9;
		posY += (mouseY - posY) / 9;

		if (isLink) {

			XsetCirculoContainer(mouseX + 10)
			YsetCirculoContainer(mouseY + 10)
			XcursorSet(mouseX)
			YcursorSet(mouseY)

			circuloTL.play()
			rotateCirculoCursor.play()

			gsap.to([follower2, follower3, follower4], {
				x: mouseX - 10,
				y: mouseY - 15,
				duration: 0,
			});

			cursoresOpacity(0)
			ScaleCursorTL.reverse()

		} else if (!isLink && !isPointer) {

			circuloTL
				.timeScale(1.1)
				.reverse()

			gsap.to([follower, follower2, follower3, follower4], {
				opacity: 1,
				x: mouseX - 10,
				y: mouseY - 10,
				duration: 0.1,
				stagger: 0.012,
			});

			XcursorSet(mouseX)
			YcursorSet(mouseY)
			cursoresOpacity(1)
			XsetCirculoContainer(mouseX + 10)
			YsetCirculoContainer(mouseY + 10)
			ScaleCursorTL.play()
			rotateCirculoCursor.pause()

		}
		else if (isPointer) {
			XcursorSet(mouseX)
			YcursorSet(mouseY)
			cursoresOpacity(0)
			XsetCirculoContainer(mouseX - 10)
			YsetCirculoContainer(mouseY - 15)
			rotateCirculoCursor.pause()

		}



	});


	function verifyHaveText(have, text) {
		if (have) {
			textInside.textContent = text
			gsap.to(textInside, {
				opacity: 1,

			})
			// scaleCursor.reverse()
		}
		else {
			gsap.to(textInside, {
				opacity: 0,

			})
			// scaleCursor
			// 	.play()

		}
	}


	document.addEventListener("mousemove", function (e) {

		mouseX = e.clientX;
		mouseY = e.clientY;

		const el = e.target.classList.contains("mouse--active") || e.target.closest(".mouse--active")
			? e.target
			: false

		document.querySelector('.cursors')
			.classList.remove('d-none')

		isPointer = e.target.classList.contains("cursor--pointer") || e.target.closest(".cursor--pointer")
			? true
			: false


		const textSection = e.target.closest("[data-texto]") || e.target.dataset.texto

		verifyHaveText(textSection, textSection ? textSection.dataset.texto : null)


		if (el) {
			el.classList.add("link--ativo")
			isLink = true

		} else {
			isLink = false
			document.querySelectorAll(".link--ativo")
				.forEach(i => i.classList.remove("link--ativo"))

		}
	});
	// document.addEventListener("scroll", function (e) {
	// 	isLink = false
	// });
}

/* 

export default function cursor() {

	const cursor = document.querySelector(".cursor"),
		circuloContainer = document.querySelector(".cursors .circulo--container"),
		textInside = document.querySelector(".cursor--text"),
		follower = document.querySelector(".cursor-follower"),
		follower2 = document.querySelector(".cursor-follower.follower2"),
		follower3 = document.querySelector(".cursor-follower.follower3"),
		follower4 = document.querySelector(".cursor-follower.follower4"),
		circulo = document.querySelector(".cursors .circulo")

	let posX = 0,
		posY = 0;

	let mouseX = 0,
		mouseY = 0;

	let isLink = false

	function rotate(el) {
		return gsap.timeline()
			.to(el, {
				rotate: 360,
				duration: 7.5,
				ease: "linear",
				repeat: -1
			})

	}


	// gsap.set(circuloContainer, { scale: 0 })

	// const rotateCirculoCursor = rotate(circulo)
	// const circuloTL = gsap.timeline({
	// 	paused: true
	// })

	// 	.from(circuloContainer, {
	// 		scale: 0,
	// 		duration: 0
	// 	})
	// 	.to(circuloContainer, {
	// 		scale: 1,
	// 		duration: 0.3
	// 	})


	////////// QUICKSETTERS
	const XsetCirculoContainer = gsap.quickSetter(circuloContainer, 'x', 'px')
	const YsetCirculoContainer = gsap.quickSetter(circuloContainer, 'y', 'px')
	const XcursorSet = gsap.quickSetter(cursor, 'x', 'px')
	const YcursorSet = gsap.quickSetter(cursor, 'y', 'px')
	const cursorOpacity = gsap.quickSetter(cursor, 'opacity')

	const scaleCursor =
		gsap.timeline()
			.from(cursor, {
				scale: 1,
				duration: 0,
			})
			.to(cursor, {
				scale: 0,
				duration: 0.2
			})

	gsap.to({}, 0.006, {
		repeat: -1,
		onRepeat: function () {
			posX += (mouseX - posX) / 9;
			posY += (mouseY - posY) / 9;

			if (isLink) {


				// XsetCirculoContainer(mouseX - 55)
				// YsetCirculoContainer(mouseY - 52)
				XcursorSet(mouseX + 10)
				YcursorSet(mouseY + 10)

				// circuloTL.play()
				// rotateCirculoCursor.play()

				gsap.to([follower, follower2, follower3, follower4], {
					x: mouseX + 10,
					y: mouseY + 10,
					duration: 0.1,
					stagger: 0.02
				});

				// gsap.set([cursor, follower, follower2, follower3, follower4], {
				// 	opacity: 0,
				// 	duration: 0,
				// });

				// cursorOpacity(1)
				// gsap.to(cursor, {
				// 	backgroundColor: '#fff',
				// })
			} else {
				gsap.to([follower, follower2, follower3, follower4], {
					x: mouseX + 10,
					y: mouseY + 10,
					duration: 0.1,
					stagger: 0.02
				});

				// circuloTL.timeScale(1.5).reverse()
				// window.circuloTL = circuloTL

				// gsap.to([follower, follower2, follower3, follower4], {
				// 	opacity: 1,
				// 	x: mouseX - 10,
				// 	y: mouseY - 10,
				// 	duration: 0.1,
				// 	stagger: 0.012,
				// 	// ease: 'sine',
				// 	scale: 1,
				// 	rotate: 0,
				// });

				// gsap.to(cursor, {
				// 	backgroundColor: '#06f784',
				// })

				XcursorSet(mouseX + 10)
				YcursorSet(mouseY + 10)
				// cursorOpacity(1)
				// XsetCirculoContainer(mouseX - 70,)
				// YsetCirculoContainer(mouseY - 70)
				// rotateCirculoCursor.progress(0)

			}


		}
	});


	function verifyHaveText(have, text) {
		if (have) {
			console.log("tem")
			textInside.textContent = text
			scaleCursor.reverse()
		}
		else {
			console.log("nao tem")
			textInside.textContent = ""
			scaleCursor
				.play()

		}
	}


	document.addEventListener("mousemove", function (e) {

		mouseX = e.clientX;
		mouseY = e.clientY;

		const el = e.target.tagName === "A"
			? e.target
			: null || e.target.closest("a") || e.target.closest(".mouse--active")

		const textSection = e.target.closest(".text--section")

		verifyHaveText(textSection, textSection ? textSection.dataset.texto : null)

		document.querySelector('.cursors').classList.remove('d-none')

		if (el) {
			el.classList.add("link--ativo")
			isLink = true

		} else {
			isLink = false
			document.querySelectorAll(".link--ativo")
				.forEach(i => i.classList.remove("link--ativo"))

		}
	});
	document.addEventListener("scroll", function (e) {
		isLink = false
	});
}
*/