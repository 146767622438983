export default function rotateEl() {
	const tl = gsap.timeline()

	tl.to(".svg--circulo svg.circulo", {
		duration: 20,
		rotate: 360,
		repeat: -1,
		ease: "linear",
		// delay: 1.5

	})
	return tl
}
