export default function initChoices(el, opts) {
	return new Choices(el, {
		silent: true,
		placeholder: false,
		searchResultLimit: 10,
		placeholderValue: 0,
		renderChoiceLimit: -1,
		itemSelectText: '',
		noResultsText: 'Nada Encontrado...',
		shouldSort: false,
		searchEnabled: false,
		searchChoices: false,
		// searchEnabled: false,
		// searchChoices: false,
		...opts

	})
}

