export default function modalVideo() {
	const elClick = document.querySelector(".conheca--video")
	const body = document.body
	const modal = document.querySelector(".modal--video")
	const video = modal.querySelector("video")
	const closes = document.querySelectorAll("[data-close='modal-video']")
	const opts = {
		classe: "video--active",
		open: false
	}
	let {
		classe,
		open
	} = opts


	const animation =
		gsap.timeline({ paused: true })
			.from(modal, {
				scale: 0,
				pointerEvents: "none",
				// skewY: 8,
			}, "<")
			.to(modal, {
				scale: 1,
				pointerEvents: "all",
				// skewY: 0,

			})


	function toogleModal(e) {
		if (!open) {
			console.log("fechado")
			open = true
			body.classList.add(classe)
			animation.play()
			video.play()

		} else {
			console.log("aberto")
			open = false
			body.classList.remove(classe)
			animation
				.progress(0.5)
				.timeScale(1.5)
				.reverse()
			video.pause()

		}
	}
	elClick
		?.addEventListener("click", toogleModal)

	closes.forEach(el => el
		?.addEventListener("click", toogleModal))

}