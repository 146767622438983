export default function carouselDestaque(
	props = {
		autoPlayTime: 0,
		autoPlay: false
	}
) {
	// ver quantidade de slides,
	// pegar e setar o ativo
	// pegar e setar o proximo e o anterior 


	const carouselContainer = document.querySelector(".carousel--container")
	const carouselWrapper = carouselContainer.querySelector(".carousel--wrapper")
	const carouselSlides = () => Array.from(carouselWrapper.querySelectorAll(".carousel--slide"))
	const abaixo1440px = window.matchMedia("(max-width: 1440px)").matches
	const paginationWrapper = document.querySelector(".carousel--pagination")

	const nextButton = () => document.querySelector(".carousel--button-next")
	const prevButton = () => document.querySelector(".carousel--button-prev")

	const textItems = carouselContainer.querySelectorAll(".carousel--text .text--item")
	let mudanceState = true

	let propsTransitionSlides = carouselSlides()

	const circleOrna = Array.from(document.querySelectorAll("#orna--circulo-cases circle"))

	let opts = {
		numberSlides: carouselSlides().length,
		slidesVisiveis: 4,
		slideActive: () => carouselSlides().filter(slide => slide.classList.contains("active"))[0],
		nextSlide: () => opts.slideActive().nextElementSibling,
		prevSlide: () => opts.slideActive().previousElementSibling, //funciona meio mal to pegando direto do array com o index
		indexPrevSlide: () => {
			const index = carouselSlides().indexOf(opts.prevSlide())
			if (index === -1) return opts.numberSlides - 1
			return index

		},
		indexNextSlide: () => carouselSlides().indexOf(opts.nextSlide()),
		indexActualSlide: () => carouselSlides().indexOf(opts.slideActive()),
		mudanceStateTrue: () => mudanceState = true,
		mudanceStateFalse: () => mudanceState = false,
		durationTransition: 0.8,
		click: false
	}
	CustomEase.create("easeSuave", ".77,0,.175,1")

	const {
		numberSlides,
		slidesVisiveis,
		slideActive,
		nextSlide,
		prevSlide,
		indexPrevSlide,
		indexNextSlide,
		indexActualSlide,

	} = opts

	const removeActiveForLastSlide = () => carouselSlides()[carouselSlides().length - 1].classList.remove("active")
	// const removeActiveForSlide = (index) => carouselSlides()[index].classList.remove("active")
	const setActiveOnSlide = (index) => carouselSlides()[index].classList.add("active")


	const setAttributeDataSlide = (item, index) => {
		item.setAttribute("data-slide", index)
		textItems[index].setAttribute("data-slide", index)
	}

	function initialState() {
		// const tl = gsap.timeline()
		setActiveOnSlide(0)

		const yValue = abaixo1440px ? 18 : 32

		const initialProps = (index) => ({
			opacity: index > 0 ? 0 : 1,
			// zIndex: -index,



		})



		propsTransitionSlides = propsTransitionSlides.map((obj, index) => {
			obj.props = initialProps(index)
			return obj
		})


		carouselSlides().forEach((slide, index) => gsap.set(slide, {
			transformOrigin: "top center",
			...initialProps(index),

		}))

		prevButton().classList.add("disabled")

	}


	function createPagination() {
		const template = carouselSlides().map((slide, index) =>
			`<span data-slide="${slide.dataset.slide}" ${index === 0 ? `class="active"` : ""} ></span>`)

		paginationWrapper.innerHTML = template.join("")

	}

	function updatePagination() {
		paginationWrapper.querySelectorAll("span").forEach(item => item.classList.remove("active"))
		paginationWrapper.querySelectorAll("span").forEach(item => {
			if (item.dataset.slide === carouselSlides()[opts.indexActualSlide()].dataset.slide) {
				item.classList.add("active")
			}
		})


	}

	function hideText(textElement, direction) {
		return new Promise(resolve => gsap.to(textElement, {
			opacity: 0,
			x: direction ? -100 : 100,
			duration: .3,
			// ease: "sine",
			onComplete: () => resolve(textElement)
		}))

	}

	function showText(textElement, direction) {
		return new Promise(resolve => {
			gsap.set(textElement, {
				opacity: 0,
				x: direction ? 90 : -90,
			})
			gsap.to(textElement, {
				opacity: 1,
				x: 0,
				duration: .4,
				// delay:0.1,
				ease: "power1",
				transformOrigin: direction ? "left center" : "top center",

				onComplete: () => resolve(textElement)
			})
		})

	}


	function refreshActiveSlide(direction) {
		prevButton().classList.remove("disabled")

		if (direction === "next") {
			setActiveOnSlide(indexNextSlide())
			carouselWrapper.appendChild(slideActive())
			removeActiveForLastSlide()

		} else {
			setActiveOnSlide(indexPrevSlide())
			carouselWrapper.insertAdjacentElement("afterbegin", carouselSlides()[indexPrevSlide()])

			carouselWrapper.children[1].classList.remove("active")
		}

		updatePagination()
		opts.mudanceStateTrue()
	}


	function refreshTextActive(direction) {
		const textActive = carouselContainer.querySelector(`.text--item.active`)

		const indexActive = direction == "next"
			? nextSlide().dataset.slide
			: carouselSlides()[indexPrevSlide()].dataset.slide

		const newTextActive = carouselContainer.querySelector(`.text--item[data-slide="${indexActive}"]`)
		const directionOftext = direction === "next" ? true : false

		hideText(textActive, directionOftext)
			.then(el => el.classList.remove("active"))
			.then(text => {
				newTextActive.classList.add("active")
				showText(newTextActive, directionOftext)


			})

	}

	function animateActiveToNext() {
		return gsap.timeline({
			overwrite: false
		})
			.fromTo(slideActive(), {
				opacity: 1,
				scale: 1,
				xPercent: 0,
			}, {
				opacity: 0,
				duration: opts.durationTransition,
				ease: "sine",
				scale: 0.5,
				xPercent: -100,

			}, "transition")
			.fromTo(nextSlide(), {
				xPercent: 100,
				scale: 0.5,
				opacity: 1,
			}, {
				xPercent: 0,
				ease: "sine",
				scale: 1,
				duration: opts.durationTransition,
				onComplete: () => {
					refreshActiveSlide("next")
				}

			}, 'transition-=0.1')


	}


	function animateActiveToPrev() {
		const prev = carouselSlides()[indexPrevSlide()]

		return gsap.timeline({
			overwrite: false

		})
			.fromTo(slideActive(), {
				opacity: 1,
				scale: 1,
				xPercent: 0,
			}, {
				opacity: 1,
				duration: opts.durationTransition,
				ease: "sine",
				scale: 0.5,
				xPercent: 100,


			}, "transition")
			.fromTo(prev, {
				xPercent: -100,
				scale: 0.5,
				opacity: 1,
			}, {
				xPercent: 0,
				ease: "sine",
				scale: 1,
				duration: opts.durationTransition,
				opacity: 1,
				onComplete: () => refreshActiveSlide("prev")

			}, 'transition-=0.1')





	}


	function handleNextSlide() {
		if (mudanceState) {
			opts.mudanceStateFalse()
			refreshTextActive("next")
			animateActiveToNext()
		}

	}

	function handlePrevSlide() {

		if (mudanceState) {
			opts.mudanceStateFalse()
			refreshTextActive("prev")
			animateActiveToPrev()

		}


	}


	function addEvents() {
		nextButton().addEventListener("click", (e) => {
			opts.click = true
			handleNextSlide()
		})
		prevButton().addEventListener("click", (e) => {
			opts.click = true
			handlePrevSlide()
		})


	}

	

	function addPanEvents() {
		const hammerCarousel = new Hammer(carouselWrapper)

		hammerCarousel.on('panleft panright panup pandown', (ev) => {
			const {
				type
			} = ev

			type === "panright" && mudanceState ? prevButton().dispatchEvent(new Event('click')) : null
			type === "panleft" && mudanceState ? nextButton().dispatchEvent(new Event('click')) : null

		});
	}

	function autoPlay() {
		const interval = setInterval(() => {
			if (opts.click) clearInterval(interval)
			else handleNextSlide()

		}, props.autoPlayTime)
	}



	function init() {
		carouselSlides().forEach(setAttributeDataSlide)
		initialState()
		addEvents()
		createPagination()
		addPanEvents()

		props.autoPlay ? autoPlay() : null
		return this


	}



	return {
		init,
		opts,
		handleNextSlide,
		handlePrevSlide,
		nextButton,
		prevButton
	}


}