export default function animateFrom(elem, direction) {

	direction = direction | 1;
	let delay = 0

	let x = 0,
		y = 0,
		scale = 1,
		skewYY = 0,
		ease = "expo"

	let duration = 1
	const att = elem.getAttribute('data-animate')
	const valorTransicao = elem.getAttribute("data-animationvalue")

	//direção
	if (att === "animate-left") {
		x = valorTransicao || -200;
		y = 0;
	} else if (att === "animate-right") {
		x = valorTransicao || 200;
		y = 0;
	} else if (att === "animate-down") {
		y = valorTransicao || elem.getBoundingClientRect().height;

	} else if (att === "animate-up") {
		y = - (valorTransicao || elem.getBoundingClientRect().height);

	}
	else if (att === "animate-scale") {
		scale = 0.6;

	}
	if (elem.hasAttribute('animate-skewy')) {
		skewYY = 5
	}
	//delay
	if (elem.dataset.delay) {
		delay = elem.dataset.delay.includes('.') ? `${elem.dataset.delay}` : `.${elem.dataset.delay}`
	}
	if (elem.dataset.duration) {
		duration = `${elem.dataset.duration}`
	}
	if (elem.dataset.ease) {
		ease = `${elem.dataset.ease}`
	}


	gsap.fromTo(elem, {
		x: x,
		y: y,
		scale: scale,
		skewY: skewYY

	}, {
		// ease: CustomEase.get("easeSuave"),
		ease: ease,
		duration: duration,
		x: 0,
		y: 0,
		autoAlpha: 1,
		delay: delay,
		scale: 1,
		overwrite: "auto",
		skewY: 0,

		onComplete: () => elem.classList.add("animou"),
		onStart: () => elem.closest('section').classList.add("animate-enter")
	})

}